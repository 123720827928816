/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-18 16:51:42
 * @LastEditors: changfeng
 * @LastEditTime: 2024-01-12 11:00:33
 */
import React, { useEffect, useState} from "react";
import { DownOutlined, UserOutlined , LoginOutlined, EditOutlined} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import { useModel, history,Link } from "@umijs/max"
import styles from "./index.scss"
import { logout } from "@/services/user"
import { MoveTokenFromLocalStorage } from "@/utils/const"


const HeaderTitle: React.FC<{ }> = ({

}) => {


    const [headTitle , setHeadTitle] = useState('')
  const {initialState} = useModel('@@initialState');

    useEffect(() => {
        const path = location.pathname
        // console.log('path',path)
      
        switch (path) {
            case '/password':
                setHeadTitle('修改密码');
                break;
            case '/admin/userList':
                setHeadTitle('用户列表');
                break;
            case '/admin/recharge':
                setHeadTitle('充值管理');
                break;
            case '/payment':
                setHeadTitle('钱包充值');
                break;
            case '/account':
                setHeadTitle('广告账户');
                break;
            case '/admin/openAccount/form':
                setHeadTitle('批量创建账户');
                break;
        }
    },[])


    history.listen((location, action) => {
        const path = location.location.pathname
        // console.log('path',path)
      
        switch (path) {
            case '/password':
                setHeadTitle('修改密码');
                break;
            case '/admin/userList':
                setHeadTitle('用户列表');
                break;
            case '/admin/recharge':
                setHeadTitle('充值管理');
                break;
            case '/payment':
                setHeadTitle('钱包充值');
                break;
            case '/account':
                setHeadTitle('广告账户');
                break;
            case '/admin/openAccount':
                setHeadTitle("批量开户");
                break

        }
    })
   
    const { currentUser } = initialState
    
    const logOut = () => {
        logout().then(res => {
            if (res.code === 200) {
                message.success("退出成功")
                MoveTokenFromLocalStorage()
                history.push('/login')
            }
        })
        
    }
    const items:MenuProps['items'] = [
            
        {
            icon:<EditOutlined />,
            label: <Link to="/password">修改密码</Link>,
            key: 'edit password',
            onClick: () => setHeadTitle('修改密码')
        },
        {
            icon:<EditOutlined />,
            label: <Link to="/realname">实名认证</Link>,
            key: 'realname',
            onClick: () => setHeadTitle('实名认证')
        },
        {
            icon:<LoginOutlined />,
            label: '退出登录',
            key: 'log out',
            onClick: () => logOut()
    },
    ]

     return (
        <div className={styles.headerLayout}>
             <div>{headTitle}</div>
            <div>
                 <Dropdown menu={{items}} placement="bottom" trigger={['click']} >
                     <span>
                         <span className={styles.userIcon}>
                             <UserOutlined color="rgba(22,33,62,.7)" style={{fontSize:12,verticalAlign:'super'}} />
                         </span>
                     <span className={styles.userName}>{ currentUser?.name}</span>
                    </span>
                 </Dropdown>
            </div>
        </div>
    )
}

export default HeaderTitle