/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-16 16:03:26
 * @LastEditors: changfeng
 * @LastEditTime: 2024-01-15 10:57:49
 */
export default (initialState: API.UserInfo) => {
  // 在这里按照初始化数据定义项目中的权限，统一管理
  // 参考文档 https://umijs.org/docs/max/access

  const superAdmin = (
    initialState && initialState?.currentUser?.roles?.includes('admin')
  );
  const Analyze = (
    initialState && initialState?.currentUser?.roles?.includes('analyze') || initialState && initialState?.currentUser?.roles?.includes('admin')
  );
  
  console.log("superAdmin",superAdmin,'Analyze',Analyze)
  return {
    superAdmin,
    Analyze
  };
};


