/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-16 16:03:26
 * @LastEditors: changfeng
 * @LastEditTime: 2024-02-21 11:34:00
 */
// 运行时配置
import { useState } from "react";
import { MenuExtra, MenuHeader , HeaderTitle} from "./components"
import { Link ,history, useModel} from "umi"
import { type MenuDataItem , type Settings as LayoutSettings, PageContainer } from '@ant-design/pro-components';
import defaultSettings from '../config/defaultSettings';
import { getCurrentUser, initJwtToken } from './services/user';

import type { RunTimeLayoutConfig } from '@umijs/max';
import { getAccessToken, MoveTokenFromLocalStorage } from '@/utils/const';
import type { RequestConfig } from 'umi';
import { stringify } from 'querystring';
import { getBcId } from "./services/bussinessCenterConfig";
import { notification, Button, message as Message } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import localeData from 'dayjs/plugin/localeData';
import WXQRcode from "@/assets/WXQRcode.jpg"
import CustomerServe from "@/components/NavFooter"
import "./global.scss"

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
// export async function getInitialState(): Promise<{ name: string }> {
//   return { name: '@umijs/max' };
// }

/** 入口文件配置dayjs插件 */
dayjs.extend(utc)

dayjs.extend(timezone)
dayjs.extend(weekday)
dayjs.extend(localeData)


const loginPath = '/login'
const registerPath = '/register'


export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: any;
  loading?: boolean;
  fetchUserInfo?: () => Promise<any>;
}> {

  if (!initJwtToken()) {
    history.push(loginPath)
   
  }
  const fetchUserInfo = async (token) => {
    try {
      const msg = await getCurrentUser(token);

      if (msg.code === 200) {
        return {
          ...msg.data.user
        }
      } else if (msg.code === 403) {
          history.push('/login')
      } else {
        return {

        };
      }
 
    } catch (error) {
      
      localStorage.removeItem("Authorization")
      const { search, pathname } = history.location;
       history.replace({
        pathname: loginPath,
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }
    return {
      
    };
  };


  // 如果不是登录页面，执行

  if (
    location.pathname !== loginPath &&
    location.pathname !== registerPath &&
    location.pathname !== '/privacy' &&
    location.pathname !== '/findPassword' &&
    location.pathname !== '/findPassword'
  ) {
    const userInfo = await fetchUserInfo();


    return {
      fetchUserInfo,
      currentUser: userInfo,
      settings: defaultSettings,
    }
  } else {
    return {
      fetchUserInfo,
      currentUser:{},
      settings: defaultSettings,
    };
  }
 
}

export const layout:RunTimeLayoutConfig = ({
  initialState
}) => {

  const [collapsed, setCollapsed] = useState<boolean>(false)
  
  return {
    // pure:true,
    logo: false,
    title: false,
    collapsed:collapsed,
    menuHeaderRender: () => <MenuHeader collapsed={collapsed} setSideExpension={(value) => setCollapsed(value)} />,
    menuExtraRender: () => <MenuExtra collapsed={collapsed}   />,
    // menuRender: (props) => {

    //   return <h1>1234</h1>
    // },
    menuItemRender: (item: MenuDataItem, dom: React.ReactNode) => {
      return <Link
        style={{ color: "#fff",paddingLeft:collapsed ? 0 : '20px',display:'block'}}
        to={item.path}
        className={location.pathname === item.path ? collapsed ? 'collpasedActiveLink': 'activeLink':''}
       
      >{dom}</Link>
    },
    onMenuHeaderClick: () => {
      console.log("123456789")
    },
    menuDataRender: (menuData) => {
      return menuData
    },
    // menuRender: (menu) => {
    //   console.log('menu', menu)
    //   const { menuData } = menu
    //   return <div>
    //     {
    //       menuData?.map(item => <Link>111</Link>)
    //     }
    //   </div>
    // },
    menuFooterRender: () => <div style={{ width: '100%',color:'#fff' }}>
      {/* {
       !collapsed && <div>
        <span style={{textAlign:'center',display:'inline-block',width:'100%'}}>联系客服</span><br />
          <span style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>请用微信扫一扫</span>
        </div>
      }
      <img onClick={() => setCollapsed(false)}  src={WXQRcode} style={{width:'100%',marginTop:10}} /> */}
     <CustomerServe  />
    </div>,
    menuProps: {
      onClick:() => setCollapsed(false)
    },
    headerRender: false,
    pageTitleRender: null,
    headerContentRender:null,
    // avatarProps: () => <div>345</div>,
    actionsRender:() => [],
    menu: {
      locale: false,
    },
    // breadcrumbRender: (route) => {
    //   console.log('breadcrumb', route)
    //   return route
    // },
    childrenRender: (children) => {
      return (
        <PageContainer title={<HeaderTitle />} breadcrumb={false}>
          {children}
        </PageContainer>
      );
      // return children
    },
    ...defaultSettings,
    // route:menus

  };
};




// 错误处理方案： 错误类型
enum ErrorShowType {
  SILENT = 0,
  WARN_MESSAGE = 1,
  ERROR_MESSAGE = 2,
  NOTIFICATION = 3,
  REDIRECT = 9,
}
// 与后端约定的响应数据格式
interface ResponseStructure {
  success: boolean;
  data: any;
  errorCode?: number;
  errorMessage?: string;
  showType?: ErrorShowType;
}


export const request: RequestConfig = {
  timeout: 300000,

  headers: { 'X-Requested-With': 'XMLHttpRequest' },

  // other axios options you want
  errorConfig: {

    errorHandler(error: any, options: any) {
      if (options?.skipErrorHandler) throw error;
      const { response = {}, message } = error;
      const { status = null } = response;
      if (String(response) !== '{}' && response.status) {
        const errorText = response.statusText;
        const msg = response.data.message || message;
        // 401时回到登录页面
        if (status === 401) {
          notification.warning({
            message: '您未登录或登录信息已过期，请重新登陆!',
          });
          // 清除token
          console.log('123456789',123)
           MoveTokenFromLocalStorage();
          // ...跳转到登录页面。
          // history.push(loginPath)
          const { search, pathname } = history.location;
          console.log('search, pathname ',search, pathname )
          history.replace({
            pathname: loginPath,
            search: stringify({
              redirect: pathname + search,
            }),
          });
        } else {
          notification.error({
            message: `请求错误 [${status}]: ${msg}`,
            description: errorText,
          });
        }
      } else if (String(response) === '{}') {
        notification.error({
          description: message || '您的网络发生异常，无法连接服务器',
          message: message || '网络异常',
        });
      } else {
        notification.error({
          description: message || '您的网络发生异常，无法连接服务器',
          message: message || '网络异常',
        });
      }
      error.response = {
        message: error.message,
      };
      Message.destroy();
      return Promise.reject(error);
    },

  //   errorThrower: (res: ResponseStructure) => {
  //     console.log('res',res)
  //     const { success, data, errorCode, errorMessage, showType } = res;
  //     if (!success) {
  //       const error: any = new Error(errorMessage);
  //       // error.name = 'BizError';
  //       error.info = { errorCode, errorMessage, showType, data };
  //       console.log('eeeeeee',error)
  //       throw Promise.reject(error); // 抛出自制的错误
  //     }
  //   },
  },
  requestInterceptors: [
    (url, options) => {
      return {
        url,
        options: {
          ...options,
          // params: { ...options.params, bc_id: getBcId() },
          params: { ...options.params },
          headers: {
            // token: getAccessToken(),
            token: getAccessToken(),
            bcType: getBcId()
          },
        },
      };
    },
  ],
  responseInterceptors: [
    (response: any) => {
      const { data = {}}: { url: string; status: number; data: any } =
        response;
      if (toString.call(data) === '[object Blob]') {
        return response
      } else {
        const { code, message } = data;
        /** 接口请求成功，做错误拦截 */
        if (code !== 200 && code !== 4003) {
        
          if (code === 401) {
            
            notification.warning({
              message: '您未登录或登录信息已过期，请重新登陆!',
            });
            // 清除token

            MoveTokenFromLocalStorage();
            const { search, pathname } = history.location;
          history.replace({
            pathname: loginPath,
            search: stringify({
              redirect: pathname + search,
            }),
          });
          } else {
            notification.info({
              message: message,
              description: data.data,
            });
          }
          return {
            data: {
              message: message,
              success: false,
            },
          };
        }
        return response;
      }
   
    },
  ],
};