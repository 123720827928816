/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-17 16:14:38
 * @LastEditors: changfeng
 * @LastEditTime: 2023-11-20 15:33:56
 */
import { request } from '@umijs/max';
import { getBcId } from './bussinessCenterConfig';
import { getQueryVariable } from "@/utils/utils"
import { Modal } from 'antd';

const TOKEN_KEY = 'Authorization'
const apiHost = `${document.location.protocol}//${document.location.host}`;

const FEISHU_AUTH_REDIRECT_URL = `${apiHost}/api/v3/auth/user/login/feishu`;

/** 获取当前的用户 GET /api/currentUser */
export async function getCurrentUser() {
  return request<{
    data: API.CurrentUser;
  }>('/api/v3/admin/user/currentUser', {
    method: 'GET',
  });
}




export async function login(body:{email:string,password:string}) {
  return request('/api/v3/auth/user/login', {
    method: 'POST',
    data: body,
  });
}

export async function getVerificationCode(body) {
  return request('/api/v3/auth/user/ecode', {
    method: 'GET',
    params: body,
  });
}

export async function register(body) {
  return request('/api/v3/auth/user/register', {
    method: 'POST',
    data: body,
  });
}

export async function logout() {
  return request('/api/v3/auth/user/logout', {
    method: 'POST',
    data: {},
  });
}

export async function findPassword(body) {
  return request('/api/v3/auth/user/resetPwd', {
    method: 'POST',
    data: body,

  });
}

export async function resetPassword(body) {
  return request('/api/v3/admin/user/updatePwd', {
    method: 'POST',
    data: body,
  });
}

export function feishuLogin() {
  const stateParams = {
    bcType: getBcId(),
    redirectUri: apiHost,
  };
  // request('/api/v3/auth/user/login/feishu', {
  //   method: 'GET',
  //   params: {
  //     code:,
  //     state:JSON.stringify(
  //       stateParams,
  //     ),
  //   }
  // })

  // console.log('stateParams', stateParams, JSON.stringify(stateParams))
  // return
  window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a2ebc9cf7839d00d&redirect_uri=${FEISHU_AUTH_REDIRECT_URL}&state=${JSON.stringify(
    stateParams,
  )}`;
  // window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a2ebc9cf7839d00d&redirect_uri=http://localhost:8000/api/v3/auth/user/login/feishu&state=${JSON.stringify(
  //      stateParams,
  //    )}`
}


export function initJwtToken() {
  let jwtToken = getQueryVariable('token');

  if (jwtToken === "10001") {
    Modal.info({
      title: "用户已被禁用，请联系管理员",
      okText:"确定"
    })
    return false
   } else if(jwtToken) {
    localStorage.setItem(TOKEN_KEY, jwtToken);
    return true;
  }
  return localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(TOKEN_KEY) !== '10001';
}

export function setJwtToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}


export async function realnameAuthorise(body) {
  return request('/api/v3/admin/user/identity/auth', {
    method: 'POST',
    data: body,
  });
}