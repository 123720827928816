/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-17 10:54:04
 * @LastEditors: changfeng
 * @LastEditTime: 2023-10-12 09:41:09
 */

import React from 'react';
import styles from './MenuHeader.scss';
import { ReactComponent as Logo } from '@/assets/expension.svg';
import   NewLogo  from '@/assets/logo.png';

import { ReactComponent as Collpase } from '@/assets/collpase.svg';

interface Props {
    collapsed: boolean;  // 是否折叠
    setSideExpension: (value:boolean) => void,
}

// 脚手架示例组件
const MenuHeader: React.FC<Props> = ({
    collapsed,
    setSideExpension
}) => {

    if (collapsed) {

        return (
            <div onMouseEnter={() => {
                setSideExpension(false)

            }
            }>
                {/* <Logo className={styles.menuHeaderCollapsedLogo} /> */}
                <img style={{width:40}} src={NewLogo} />
            </div>
        )
    } else {
        return (
            <div className={styles.menuHeaderContainer}>
                <div className={styles.menuHeaderLayout}>
                    <div className={styles.menuHeaderLayoutLeft}>
                        <div>
                            {/* <Logo   /> */}
                            <img style={{width:48,}} className={styles.rotateLogo} src={NewLogo} />
                        </div>
                        <div className={styles.menuHeaderTitle}>NewRate</div>
                        {/* <div className={styles.menuHeaderTitle} style={{marginTop:'-20px',fontSize:18}}><span>CLASSIC ONCE</span><span> MORE LTD</span></div> */}
                    </div>
                    <div className={styles.menuHeaderLayoutRight}>
                        {/* <ExpensionSvg /> */}
                        <Collpase
                            onClick={() => {
                                console.log('1122234shdvbjwdh ')
                                setSideExpension(true)
                            }
                            }
                        />
                        
                    </div>
                </div>
            </div>
        );
    }


};

export default MenuHeader;
