/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-17 10:54:04
 * @LastEditors: changfeng
 * @LastEditTime: 2023-09-12 15:04:06
 */

import React, {useState} from 'react';
import styles from './MenuExtra.scss';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { ReactComponent as RefreshIcon } from '@/assets/RefreshIcon.svg';
import { useModel } from '@umijs/max';

interface Props {
    // setSideExpension: (value: boolean) => void,
    collapsed:boolean
}

// 调用户信息时，获取用户余额
const MenuExtra: React.FC<Props> = ({
    collapsed
}) => {

    const { initialState, setInitialState } = useModel('@@initialState');
    const { fetchUserInfo } = initialState

    const [loading,setLoading] = useState(false)

    if (!collapsed) {
        return (
            <div
                className={styles.menuExtraContainer}
            >
                <div className={styles.userBalance}>
                    <span className={styles.userbalanceTitle}>钱包余额</span> 
                    <Tooltip title="余额将被用于充值广告账户">
                        <span className={styles.toolTipCycle}>
                            <ExclamationCircleOutlined  style={{fontSize:12,width:12,height:12}} />
                        </span>
                    </Tooltip>
                </div>

                <div className={styles.userBalanceCountContainer}>
                    <div className={styles.userBalanceCount}>${ initialState?.currentUser?.balance}</div>
                    <span className={styles.menuExtraAddBalance}
                        onClick={async () => {
                            setLoading(true)
                            let current = await fetchUserInfo()
                            if (current?.id) {
                                setLoading(false)
                                setInitialState({
                                    ...initialState,
                                    currentUser:{...current}
                                })
                            }
                        }}
                    >
                        <RefreshIcon className={loading ? styles.activeRefresh : styles.refresh} />
                    </span>
                </div>
            
            </div>
        );
    } else {
        return <></>
    }

};

export default MenuExtra;
