/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2024-01-08 15:07:17
 * @LastEditors: changfeng
 * @LastEditTime: 2024-01-16 11:19:27
 */
import { useState } from "react"
import { ReactComponent as StaffServeIcon } from "@/assets/staffServe.svg"
import { ReactComponent as CompanyWechatIcon } from "@/assets/wechat.svg"
import People from "@/assets/people.jpeg"
import WXCode from "@/assets/WXQRcode.jpg"
import { Image } from "antd"
const CustomerServe = () => {

    const [staffServe, setStaffServe] = useState(false)
    const [companyWechat,setCompanyWeChat] = useState(false)
    return (
        <div>
            <div style={{display:'flex',justifyContent:"space-around"}}>
                <div><StaffServeIcon color="#fff" onMouseEnter={() => {setStaffServe(true)}} onMouseLeave={() => {setStaffServe(false)}} /></div>
                <div><CompanyWechatIcon onMouseEnter={() => {setCompanyWeChat(true)}} onMouseLeave={() => {setCompanyWeChat(false)}} /></div>

            </div>

            <div style={{bottom:80,position:'absolute',zIndex:999}}>
                {
                    staffServe && <div>
                    <span style={{textAlign:'center',display:'inline-block',width:'100%'}}>有疑问联系客服</span><br />
                        <span style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>请用微信扫一扫</span>
                        <Image style={{width:"80%",marginLeft:20}} src={WXCode}/>
                    </div>
                }
                {
                    companyWechat && <div>
                    <span style={{textAlign:'center',display:'inline-block',width:'100%'}}>关注公众号</span><br />
                        <span style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>一手政策实时更新</span>
                        <Image style={{width:"80%",marginLeft:20}} src={People}/>
                    </div>
                }
              
            </div>
        </div>
    )
}

export default CustomerServe