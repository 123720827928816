/*
 * @Description: 
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-08-23 14:40:12
 * @LastEditors: changfeng
 * @LastEditTime: 2023-08-23 14:40:26
 */
export function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }
  