/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-05-24 09:43:39
 * @LastEditors: changfeng
 * @LastEditTime: 2024-01-11 18:09:47
 */
export const GNH_BC = 'gnh';
export const HWH_BC = 'hwh';
// export const ZT_BC = 'zhengtian';
export const ZT_BC = 'zt';

const HOSTNAME_BC_ID_MAP = {
  localhost: GNH_BC, // 杭立询主体的BC
//   'tiktok-business-admin.xinsulv.com': '6973924533950480386', // 杭立询主体的BC
//   'tiktok-business-admin.adsurpass.com': '7107430972940992514', // 海外户BC
//   'tiktok-business-admin.newrate.net': '7161356844022169601', // 代理商业务主体的BC
//   'tiktok-business-admin-2.newrate.net': '7229250006648193026', // 代理商业务主体的BC
//   'tiktok-business-admin.xinsulv.cn': '7155780945373593601', // 新速率主体的BC, 内部投手使用
//   'tiktok-business-admin-hwh2.adsurpass.com': '7000338652068904962', // 海外户BC
//   'tiktok-business-admin.test': '6973924533950480386',
  'tiktok-business-admin-gnh.test': GNH_BC,
  "tt-biz-adm-test.xinsulv.com":GNH_BC,
//   'tiktok-business-admin-hwh.test': HWH_BC,
  'tt-biz-adm.newrate.net': GNH_BC,
  'tt-agent-test.xinsulv.com' : GNH_BC,
  'tt-biz-adm.adsurpass.com': HWH_BC,
  'tt-biz-adm-bk-hlx1.newrate.net': '6973924533950480386', // 杭立询主体的BC(查询历史数据使用)
  'tt-biz-adm-bk-hlx2.newrate.net': '7161356844022169601', // 代理商业务主体的BC(查询历史数据使用)

  /**更新测试 */
//   'tiktok-business-admin-zt.test': ZT_BC,

  /** 新增bc */
  'tt-biz-adm-zt.newrate.net': ZT_BC,
};

const BC_ID_NAME_MAP = {
  '6973924533950480386': 'NewRate Limited(HLX1)',
  '7161356844022169601': 'NewRate Limited(HLX2)',
  '7229250006648193026': 'NewRate Limited(HLX2)-2',
  '7155780945373593601': '新速率&NewRate LLC',
  '7107430972940992514': 'NewRate LLC(HWH)',
  '7000338652068904962': 'NewRate LLC(HWH2)',
  [GNH_BC]: 'NewRate Limited',
  [HWH_BC]: 'NewRate LLC(HWH)',

  /** 测试更新 */
  [ZT_BC]: 'NewRate LLC(ZT)',
};

export function getBcId() {
  return HOSTNAME_BC_ID_MAP[document.location.hostname];
}

export function getBcName() {
  return BC_ID_NAME_MAP[getBcId()];
}

const HWH_BC_IDS = ['7107430972940992514', '7000338652068904962', HWH_BC];

export function isHWH() {
  return HWH_BC_IDS.includes(getBcId());
}
